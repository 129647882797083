import { body12 } from "components/style/body";
import { motion } from "framer-motion";
import { ModalStandardProps } from "modules/defines/interfaces";
import Button from "components/ui/Button";
import { palette } from "modules/defines/styles";
import styled from "styled-components";
import { useEffect, useState } from "react";
import ReactCrop, { Crop } from "react-image-crop";
import { productAPI } from "api";
import { dataURLtoFile } from "modules/functions/utils";

interface ModalCropRepImageProps extends ModalStandardProps {
  file: File;
  productId: number; // DB ID
  productIdStr: string; // 상품번호
  mid: number; // Manager ID
  setProductInfo: (data: any) => void;
  //   setImageMainUploadLoading: (isLoading: boolean) => void;
}

const ModalCropRepImage = ({ ...props }: ModalCropRepImageProps) => {
  const {
    subject = "",
    description,
    onClose,
    file,
    productId,
    productIdStr,
    mid,
    setProductInfo,
    // setImageMainUploadLoading,
  } = props;
  const [image, setImage] = useState<string | null>(null);
  const [crop, setCrop] = useState<Crop>({
    unit: "px",
    width: 10,
    height: 10,
    x: -100,
    y: -100,
  });
  const [imageRef, setImageRef] = useState<HTMLImageElement | null>(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  }, [file]);

  const onImageLoaded = (imageElement: HTMLImageElement) => {
    setImageRef(imageElement);
  };

  const onCropComplete = () => {
    if (imageRef && crop.width && crop.height) {
      const canvas = document.createElement("canvas");
      const scaleX = imageRef.naturalWidth / imageRef.width;
      const scaleY = imageRef.naturalHeight / imageRef.height;
      const pixelRatio = window.devicePixelRatio; // 사용자의 화면 해상도에 따라 픽셀 비율을 고려
      canvas.width = crop.width * scaleX * pixelRatio;
      canvas.height = crop.height * scaleY * pixelRatio;
      const ctx = canvas.getContext("2d");

      if (ctx) {
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0); // 고해상도 설정
        ctx.imageSmoothingQuality = "high";

        ctx.drawImage(
          imageRef,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width * scaleX,
          crop.height * scaleY
        );

        const base64Image = canvas.toDataURL("image/jpeg");
        setCroppedImageUrl(base64Image);
      }
    }
  };

  const onSubmit = async () => {
    setIsLoading(true);
    let postData = new FormData();
    const RandomNumber = (Math.random() * (99 - 1) + 1).toFixed();
    const newName = `${productId}_${RandomNumber}`;
    const originalName = file.name;
    const lastDotIndex = originalName.lastIndexOf(".");
    const extension = originalName.substring(lastDotIndex);
    const newNameWithExtension = newName + extension;

    const croppedImageFile = dataURLtoFile(
      croppedImageUrl || "",
      newNameWithExtension
    );

    postData.append("img", croppedImageFile);

    let headerData = { id: productId, type: "represent" };
    await productAPI.uploadImage(postData, headerData).then(async (res) => {
      if (res.status === 200) {
        alert(`대표 이미지가 등록되었습니다.`);
      } else {
        alert(
          "이미지 등록에 실패하였습니다. 해당 현상이 지속될 경우 관리자에게 문의바랍니다."
        );
      }
      await productAPI
        .getProductDetail(productIdStr, { mid: mid })
        .then((res) => {
          if (onClose) onClose();
          setProductInfo(res.data?.product);
          //   setImageMainUploadLoading(false);
        });
    });
  };

  return (
    <ModalWrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.5,
      }}
    >
      <Subject>{subject}</Subject>
      <Description>{description}</Description>

      <ContentsContainer>
        <InputContainer>
          <SubTitle>{"원본 이미지"}</SubTitle>
          {image && (
            <CropContainer>
              <StyledReactCrop
                crop={crop}
                onChange={(newCrop) => setCrop(newCrop)}
                onComplete={onCropComplete}
                aspect={1 / 1}
              >
                <ImageContainer
                  src={image}
                  alt="CROP ME"
                  onLoad={(e) => onImageLoaded(e.currentTarget)}
                />
              </StyledReactCrop>
            </CropContainer>
          )}
        </InputContainer>
        <OutputContainer>
          <SubTitle>{"수정된 이미지"}</SubTitle>
          {croppedImageUrl && (
            <StyledCroppedImage src={croppedImageUrl} alt="Cropped preview" />
          )}
        </OutputContainer>
      </ContentsContainer>
      <ButtonContainer>
        {crop.x === -100 && (
          <WarningText>
            원본 이미지에 마우스를 드래그하여 이미지를 정방형으로 잘라주세요.
          </WarningText>
        )}
        <Button
          disabled={isLoading || crop.x === -100}
          isLoading={isLoading}
          styleType={isLoading ? "outlineDisabled" : "primary"}
          size="60"
          type="submit"
          onClick={onSubmit}
        >
          {isLoading ? "로딩중" : "제출"}
        </Button>
      </ButtonContainer>
    </ModalWrapper>
  );
};

const ModalWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  max-height: 95vh;
  max-width: 95vw;
`;

const Subject = styled.h2`
  color: ${palette.neutral1000};
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  margin: 0;
`;

const Description = styled.div`
  width: 100%;
  ${body12}
  color: ${palette.neutral600};
  white-space: pre-line;
  margin: 8px 0 16px;
`;
const WarningText = styled.div`
  ${body12}
  color: ${palette.secondary400};
  margin-left: 2px;
  margin-bottom: 4px;
`;

const ContentsContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow-y: scroll;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 30vw;
`;

const SubTitle = styled.div`
  margin-left: 2px;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  margin-bottom: 8px;
  color: ${palette.neutral700};
  font-family: Pretendard;
`;

const CropContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImageContainer = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const StyledReactCrop = styled(ReactCrop)`
  width: 100%;
  height: 100%;

  .ReactCrop__crop-selection {
    border: 4px dashed #0097ff;
    z-index: 2000000; /* 크롭 선택 영역이 이미지 위에 위치하도록 z-index 조정 */
    position: absolute;
    cursor: grab;
  }

  .ReactCrop__crop-mask {
    display: none; /* SVG 마스크 제거 */
  }
`;

const OutputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  width: 30vw;
`;

const StyledCroppedImage = styled.img`
  max-width: 100%;
  border: 2px solid #ddd;
`;

const ButtonContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export default ModalCropRepImage;
